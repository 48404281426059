import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Employee Portal Coming Soon.</p>
        <p style={{ fontSize: "0.9em" }}>
          The Health Portal has moved to{" "}
          <a
            className="App-link"
            href="https://portal.kindbio.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://portal.kindbio.com/
          </a>
        </p>
      </header>
    </div>
  );
}

export default App;
